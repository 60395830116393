import React from 'react';
import { useListingJobByDocId } from '../../data/listings';
import { useState } from 'react';
import { useEffect } from 'react';
import { formatString } from '../seeker/details/New_UI/Components/util';
import { useParams } from 'react-router-dom';
import { formatRateRange } from '../seeker/details/New_UI/Components/util';
import { sanitizeMarkup } from '../../util/sanitization/sanitize-html';
// import { FaApple, FaGooglePlay } from 'react-icons/fa';

const JobListingShare = ({ id, share }) => {
    const { data } = useListingJobByDocId(id)
    const [jobsData, setJobsData] = useState()
    useEffect(() => {
        setJobsData(data)
    }, [data])

    const handleClick = () => {
        window.open("https://jobs.meetarti.net/auth/signin", '_blank', 'noopener,noreferrer');
    }
    function formatCompensation(data) {
        if (data?.salary) {
            const minSalary = data.salary.min.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            const maxSalary = data.salary.max.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            return `${minSalary} - ${maxSalary} Yearly`;
        }
        if (data?.hourly) {
            const minHourly = data.hourly.min.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            const maxHourly = data.hourly.max.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            return `${minHourly} - ${maxHourly} Hourly`;
        }
        if (data?.commission) {
            const commission = data.commission.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            return ` Commission ${commission}`;
        }
        return "";
    }



    return (
        <div style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg-1.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
            className={`h-60 fixed w-full z-50 py-5 px-36 bg-cover`}>
            <div className=' ml-[-100px] w-48'>
                <img src="/images/InstantTeamsLogo_Main_1.png" alt="Instant Teams Logo" />
            </div>
            <div className=" z-50 mx-auto my-8 p-6 border border-gray-300  overflow-y-auto rounded-lg shadow-md bg-white">
                {/* Header Section */}
                <div className="flex justify-between items-start">
                    <div className="flex items-center">
                        <img
                            src={jobsData?.companyLogo}
                            alt="Company Logo"
                            className="w-16 h-16 rounded object-contain"
                        />
                        <div className="ml-4">
                            <h2 className="text-xl text-instant-teams-blue-Main font-bold">{jobsData?.jobName}</h2>
                            <p className="text-gray-500 text-sm ">{jobsData?.companyFullName}</p>
                        </div>
                    </div>
                    <button onClick={handleClick} className="bg-instant-teams-blue-Main text-white py-2 px-8 rounded-full">
                        Join Now To Apply
                    </button>
                </div>

                {/* Job Details */}
                <div className="flex mt-4 space-x-4">
                    <div className="bg-[#CCD6FF] text-center p-2 px-6 items-center rounded-md ">
                        <p className="text-sm text-gray-500 items-center">Work Type</p>
                        <p className="font-bold items-center text-gray-700 ">{formatString(jobsData?.workSettings)}</p>
                    </div>
                    <div className="bg-[#CCD6FF] text-center items-center p-2 px-6 rounded-md ">
                        <p className="text-sm text-gray-500 items-center ">Work Setting</p>
                        <p className="font-bold">{formatString(jobsData?.workType)}</p>
                    </div>

                    {jobsData?.payRate?.salary && (
                        <div style={{ backgroundColor: "#3E4C9533" }} className={`${jobsData?.payRate?.commission ? "cursor-pointer" : ""} bg-[#3E4C9533] py-2 px-6  rounded-lg `}>
                            <p className="text-instant-teams-blue-Main text-center text-sm">{jobsData?.payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                            <p className="font-semibold text-instant-teams-blue-Main text-center">{formatRateRange("salary", jobsData?.payRate?.salary)}</p>
                        </div>
                    )}
                    {jobsData?.payRate?.hourly && (
                        <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                            <p className="text-instant-teams-blue-Main text-center text-sm">{jobsData?.payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                            <p className="font-semibold text-instant-teams-blue-Main">{formatRateRange("hoursPerWeek", jobsData?.payRate?.hourly)}</p>
                        </div>
                    )}
                    {jobsData?.payRate?.commission && !jobsData?.payRate?.salary && !jobsData?.payRate?.hourly && (
                        <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                            <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                            <p className="font-semibold text-instant-teams-blue-Main">Commission Based</p>
                        </div>
                    )}
                    {jobsData?.payRate?.freeInternship && (
                        <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                            <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                            <p className="font-semibold text-instant-teams-blue-Main">Unpaid Internship</p>
                        </div>
                    )}
                </div>

                {/* Mobile App Section */}
                <div style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg-1.png'})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }} className="mt-4  p-4 rounded-lg flex items-center justify-between">
                    <div>
                        <h3 className="text-xl text-gray-700 font-bold">
                            Download Our Mobile App to Join Now!
                        </h3>
                        <p className="text-gray-500 text-sm">
                            Experience the convenience of connecting with opportunities on the go
                            with our mobile app.
                        </p>
                    </div>
                    <div className="flex gap-2">
                        <div className="text-center pt-4">
                            <a
                                className="h-12 inline-block"
                                href={`https://apps.apple.com/us/app/instant-teams-marketplace/id1666030415?itsct=apps_box_badge&amp;itscg=30200`}
                                target="_blank"
                            >
                                <img
                                    className="w-full h-11"
                                    src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1685577600`}
                                    alt="Download on the App Store"
                                />
                            </a>
                        </div>
                        <div className="text-center">
                            <a
                                className="h-16 mt-1 inline-block"
                                href="https://play.google.com/store/apps/details?id=com.instantteams.athena&utm_source=landingPage&utm_campaign=launch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&utm_source=build.instantteams.com&utm_campaign=Build&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                target="_blank"
                            >
                                <img
                                    className="w-full h-16"
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Job Description Section */}
                {jobsData?.jobDescription?.whatsInItForYou && <div className="mt-4">
                    <h3 className="text-lg text-gray-700 font-bold mb-2">What's in it for you?</h3>
                    <p className="text-gray-500 text-sm">
                        <div
                            className="dangerously"
                            dangerouslySetInnerHTML={sanitizeMarkup(
                                jobsData?.jobDescription?.whatsInItForYou
                            )}
                        ></div>
                    </p>
                </div>}

                {jobsData?.jobDescription?.dayInLife && <div className="mt-4">
                    <h3 className="text-lg text-gray-700 font-bold mb-2">A Day in the Life</h3>
                    <p className="text-gray-500 text-sm">
                        <div
                            className="dangerously text-sm"
                            dangerouslySetInnerHTML={sanitizeMarkup(
                                jobsData?.jobDescription?.dayInLife
                            )}
                        ></div>

                    </p>
                </div>}

                {jobsData?.jobDescription?.mustHaves && <div className="mt-4">
                    <h3 className="text-lg text-gray-700 font-bold mb-2">Must Haves/What You Bring to the Table</h3>
                    <p className="text-gray-500 text-sm">
                        <div
                            className="dangerously"
                            dangerouslySetInnerHTML={sanitizeMarkup(
                                jobsData?.jobDescription?.mustHaves
                            )}
                        ></div>

                    </p>
                </div>}


            </div>
        </div>
    );
};

export default JobListingShare;
